.titlewrapper
{
    text-align: center;
    font-weight: bold;
    font-size: larger;
    font-family: 'Times New Roman', Times, serif;
}
.youtubeVideosWrapper {
    background-color: #fff;
    padding: 50px;
}
.singleVideosWrapper {
    background-color: #fff;
    box-shadow: 4px 0px 13px black;
    margin-bottom: 30px;
    max-height: 393px;
    overflow: hidden;
}
.videoInfoWrapperv{
    padding: 10px 20px;
    text-align: left;
}
.videoDesc {
    font-size: 0.6em;
    color: green;
}
.videoTitle {
    font-weight: 700;
    line-height: 1.2em;
}
.videoTitle a {
    color: black;
}